<template>
  
    <banner-null></banner-null>

    <section class="content-home border-bottom-red">
        <div class="container-fluid">
            <div class="row mb-3">
                <div class="col-2">
                    <div class="content-block">
                        <img class="icon-red img-left" src="../../assets/images/manajemen.png" alt="icon">
                    </div>
                </div>

                <div class="col-10">
                    <div class="content-block">
                        <h3>JAJARAN <span class="alternate">MANAJEMEN</span></h3>

                        <div class="row">
                            <div class="col-8">
                                <h4 class="text-blue">DEWAN DIREKSI</h4>
                            </div>

                            <div class="col-4">
                                <div class="text-end">
                                    <a>
                                        <router-link to="/manajemen">
                                            <p class="text-primary"><b>DEWAN KOMISARIS</b> <i class="zmdi zmdi-caret-right-circle"></i></p>
                                        </router-link>
                                    </a>
                                </div>
                            </div>
                        </div>

                        <template v-for="data in direksi" :key="data.id">
                            <div v-if="data.align === 'text-right'">
                                <div class="row">
                                    <div class="col-lg-8">
                                        <h3> {{data.nama}} | <span class="alternate"> {{data.jabatan}} </span> </h3>
                                        <p v-html="data.biodata"></p>
                                    </div>

                                    <div class="col-lg-4 d-flex justify-content-center">
                                        <img :src="path + data.foto" alt="foto komisaris" class="img-komisaris">
                                    </div>
                                </div>
                            </div>

                            <div v-else>
                                <div class="row mt-3 mb-3">
                                    <div class="col-lg-4 d-flex justify-content-center">
                                        <img :src="path + data.foto" alt="foto komisaris" class="img-komisaris">
                                    </div>

                                    <div class="col-lg-8">
                                        <h3> {{data.nama}} | <span class="alternate"> {{data.jabatan}} </span> </h3>
                                        <p v-html="data.biodata"></p>
                                    </div>
                                </div>
                            </div>
                        </template>

                        <div class="flex-container mt-3">
                            <div class="flex-item bg-blue">
                                <div class="d-flex justify-content-center align-items-center mt-3">
                                    <router-link to="/tentang_kami">
                                        <img src="../../assets/images/sejarah.png" width="50" height="50" alt="icon">
                                        <p class="text-white">SEJARAH</p>
                                    </router-link>
                                </div>
                            </div>

                            <div class="flex-item bg-blue">
                                <div class="d-flex justify-content-center align-items-center mt-3">
                                        <router-link to="/visi_misi">
                                            <img src="../../assets/images/visi_misi.png" width="50" height="50" alt="icon">
                                            <p class="text-white">VISI - MISI</p>
                                        </router-link>
                                </div>
                            </div>

                            <div class="flex-item bg-blue">
                                <div class="d-flex justify-content-center align-items-center mt-3">
                                    <router-link to="/budaya">
                                        <img src="../../assets/images/budaya.png" width="50" height="50" alt="icon">
                                        <p class="text-white">BUDAYA</p>
                                    </router-link>
                                </div>
                            </div>

                            <div class="flex-item bg-red">
                                <div class="d-flex justify-content-center align-items-center mt-3">
                                    <router-link to="/manajemen">
                                        <img src="../../assets/images/manajemen.png" width="50" height="50" alt="icon">
                                        <p class="text-white">MANAJEMEN</p>
                                    </router-link>
                                </div>
                            </div>

                            <div class="flex-item bg-blue">
                                <div class="d-flex justify-content-center align-items-center mt-3">
                                    <router-link to="/tata_kelola">
                                        <img src="../../assets/images/tata_kelola.png" width="50" height="50" alt="icon">
                                        <p class="text-white">TATA KELOLA</p>
                                    </router-link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>

</template>

<script>

import bannerNull from '../banner/banner-null.vue'
import axios from 'axios'

export default {
    name: 'pageDireksi',
    components: {
        bannerNull
    },
    data () {
        return {
            direksi: [],
            // path: 'http://localhost:8080/assets/direksi/',
            path: 'https://api.plnbag.co.id/assets/direksi/'
        }
    },
    mounted () {
        this.loadData()
    },
    methods: {
        loadData () {
            axios.get('direksi_web')
                .then((res) => {
                    this.direksi = res.data.data
                })
        }
    }
}
</script>

<style scoped>
    .content-home {
        position: relative;
    }

    .content-home .content-block {
        padding: 25px 0 0;
    }

    .content-home .content-block h3 {
        color: #0158a7;
        text-transform: uppercase;
        font-weight: 600;
        margin-bottom: 15px;
        font-size: 17px;
    }

    .content-home .content-block span.title-w {
        color: #fff;
        text-transform: uppercase;
        font-weight: 600;
        margin-left: 10px;
        margin-bottom: 15px;
        font-size: 17px;
    }

    .content-home .content-block p.text-w {
        color: #fff;
        margin-bottom: 20px;
        font-weight: 200;
        text-align: justify;
    }

    .content-home .content-block p {
        color: #404041;
        margin-bottom: 20px;
        font-weight: 200;
        text-align: justify;
    }

    .content-home .image-block {
        position: absolute;
        right: 5%;
        bottom: 0;
    }

    @media (max-width: 767px) {
        .content-home .image-block {
            display: none;
        }
    }

    .border-bottom-red {
        border-bottom: 5px solid #ea2e23;
    }

    .icon-red {
        background-color: #ea2e23;
        border-radius: 5px;
        padding: 10px;
        float: right;
    }

    @media (max-width: 767px) {
        .icon-red {
            float: none;
        }
    }

    .img-left {
        width: 85px;
        height: auto;
    }

    @media screen and (max-width: 767px) {
        .img-left {
            width: 45px;
            height: auto;
        }
    }

    span.alternate {
        color: #ea2e23;
        font-weight: 800;
    }

    .text-blue {
        color: #0158a7;
    }

    a {
        text-decoration: none;
    }
    
    a.text-red {
        color: #ea2e23;
    }

    .img-komisaris {
        width: 50%;
        height: auto;
    }

    @media screen and (max-width: 767px) {
        .img-komisaris {
            width: 100%;
            height: auto;
        }
    }

    .flex-container {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-wrap: wrap;
    }

    .flex-container div.flex-item {
        flex: 0 0 180px;
        margin: 2px 2px;
    }

    .bg-blue {
        background-color: #0158a7;
    }

    .bg-red {
        background-color: #ea2e23;
    }

    .flex-container div.flex-item div.d-flex a {
        text-decoration: none;
    }
</style>