<template>
  
    <section class="banner banner-full">
        <br>
    </section>

    <!-- banner tag -->
    <section class="banner-tag">

    </section>

</template>

<script>
export default {
    name: 'bannerNull'
}
</script>

<style scoped>
    .banner {
        /*padding: 250px 0 150px;*/
        /*padding: 300px;*/
        position: relative;
        margin-top: 100px;
    }

    .banner-tag {
        background: #0158a7;
        padding-bottom: 30px;
        border-bottom: 5px solid #ea2e23;
    }

    .banner-tag span {
        float: right;
        text-transform: uppercase;
        font-weight: 200;
        color: #fff;
        padding-right: 35px;
        padding-top: 3px;
    }
</style>